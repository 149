.about h1 {
    font-weight: 400;
    font-size: 48px;
    line-height: 100%;
    text-transform: uppercase;
}

.about p {
    font-weight: 400;
    font-size: 30px;
    line-height: 100%;
}

.about a {
    text-decoration: none;
    text-transform: uppercase;
}

@media (max-width: 900px) {
    h1 {
        font-size: 32px !important;
    }

    p {
        font-size: 18px !important;
    }

    span {
        font-size: 11px !important;
    }
}

span {
    text-transform: uppercase;
    font-size: 12px;
}

.about {
    background: #000;
    color: #fcfbf1;
    min-height: 100vh;
}

.about section.about-us {
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about section.about-us .about-us-copy {
    width: 70%;
    margin: 2em;
    gap: 12em;
}


.about section.about-us .about-us-copy .about-us-copy-p {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
}

.about section.about-us .about-us-copy .about-us-copy-p p {
    text-align: justify;
}

#about-sticky-wrap {
    position: relative;
    padding: 0;
}

.about-sticky-1 {
    width: 100vw;
    height: 100vh;
    position: sticky;
    top: 0;
    background: #fcfbf1;
    color: #000;
    padding: 4em 2em 4em 2em;
}

.about-sticky-2 {
    width: 100vw;
    height: 100vh;
    position: sticky;
    top: 0;
    background: #000;
    color: #fff;
    padding: 4em 2em 4em 2em;
}

.about-sticky .sticky-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4em 0 6em 0;
}

.sticky-content h1 {
    font-size: 60px !important;
}

.sticky-content h1.num {
    font-size: 120px !important;
}

.hero-img-container img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    -webkit-filter: grayscale(80%);
    filter: grayscale(80%);
}


@media (max-width: 900px) {
    .about p {
        line-height: normal;
    }

    .about-sticky-2 {
        height: 100%;
        padding: 4em 2em 0em 2em;
    }

    .about section.about-us .about-us-copy {
        align-items: flex-start;
        flex-direction: column-reverse;
        gap: 2em;
        width: 100%;
    }

    .sticky-content h1 {
        font-size: 28px !important;
    }

    .sticky-content h1.num {
        font-size: 46px !important;
    }

    .more-clients .more-clients-logos {
        flex-direction: column;
    }

    .more-clients .more-clients-logos .more-clients-logo {
        width: 100%;
    }

    .office h1#office {
        font-size: 50px;
    }
}

@media (max-width: 900px) {
    section.hero-img .hero-img-copy {
        flex-direction: column;
        gap: 2em;
    }

    section.hero-img .hero-img-copy .hero-img-copy-h1,
    section.hero-img .hero-img-copy .hero-img-copy-p {
        width: 100%;
    }

    section.hero-img .hero-img-copy .hero-img-copy-p p {
        text-align: left !important;
    }

    section.projects .projects-copy,
    section.projects-header .projects-copy {
        flex-direction: column;
    }

    section.projects .projects-copy .projects-copy-h1,
    section.projects-header .projects-copy .projects-copy-h1 {
        width: 100%;
    }

    section.projects .projects-copy .projects-copy-ws,
    section.projects-header .projects-copy .projects-copy-ws {
        display: none;
    }

    .projects-list {
        flex-direction: column;
    }

    .projects-list .project .project-name p,
    .projects-list .project .project-category p {
        font-size: 12px;
    }

    section.services {
        flex-direction: column-reverse;
        gap: 2em;
    }

    section.services .services-copy-h1,
    section.services .services-copy-p {
        width: 100%;
    }

    section.services .services-copy-p {
        text-align: left !important;
    }

    section.clients .client-copy {
        flex-direction: column;
    }

    section.clients .client-copy .client-copy-p {
        padding: 0 0;
    }

    section.clients .client-copy .client-copy-ws {
        display: none;
    }

    section.clients .client-copy .client-copy-p {
        width: 100%;
        padding: 0 0 !important;
        margin-bottom: 4em !important;
    }

    section.careers .careers-copy {
        width: 100% !important;
    }

    section.contact .contact-copy {
        width: 100% !important;
    }

    .about section.about-us .about-us-copy .about-us-copy-p span {
        font-size: 50px !important;
    }

    .about section.about-us .about-us-copy .about-us-copy-p p {
        font-size: 18px !important;
    }

    .about-sticky .sticky-content .resume {
        padding: 0em 0 6em 0;
    }
}

html.has-scroll-smooth {
    overflow: hidden;
}

html.has-scroll-dragging {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.has-scroll-smooth body {
    overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
    min-height: 100vh;
}

[data-scroll-direction="horizontal"] [data-scroll-container] {
    height: 100vh;
    display: inline-block;
    white-space: nowrap;
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    height: 100%;
}

.c-scrollbar {
    position: absolute;
    right: 0;
    top: 0;
    width: 11px;
    height: 100%;
    transform-origin: center right;
    transition: transform 0.3s, opacity 0.3s;
    opacity: 0;
}

.c-scrollbar:hover {
    transform: scaleX(1.45);
}

.c-scrollbar:hover,
.has-scroll-scrolling .c-scrollbar,
.has-scroll-dragging .c-scrollbar {
    opacity: 1;
}

[data-scroll-direction="horizontal"] .c-scrollbar {
    width: 100%;
    height: 10px;
    top: auto;
    bottom: 0;
    transform: scaleY(1);
}

[data-scroll-direction="horizontal"] .c-scrollbar:hover {
    transform: scaleY(1.3);
}

.c-scrollbar_thumb {
    position: absolute;
    top: 0;
    right: 0;
    background-color: black;
    opacity: 0.5;
    width: 7px;
    border-radius: 10px;
    margin: 2px;
    cursor: -webkit-grab;
    cursor: grab;
}

.has-scroll-dragging .c-scrollbar_thumb {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
    right: auto;
    bottom: 0;
}