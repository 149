.footer {
  width: 100%;
  opacity: 0.5;
}

.footer .divider {
  margin: 0.75em 0;
  width: 100%;
  height: 0.65px;
  background: var(--text);
}

.footer .footer-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer .footer-content .footer-col:nth-child(1) {
  display: flex;
}

.footer .footer-content .footer-col:nth-child(4) {
  display: flex;
}

.footer .footer-content .footer-col .arrow {
  background: var(--text);
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-right: 0.25rem;
}

.footer .footer-content .footer-col p {
  font-size: 14px;
}

.footer .footer-content .footer-col .arrow p {
  opacity: 1;
  color: var(--bg);
}

.footer-socials {
  display: flex;
  gap: 1rem;
}

.footer-socials a {
  display: inline-block;
}

.footer-socials svg {
  width: 20px;
  height: 20px;
}

@media (max-width: 900px) {
  .footer {
    position: absolute;
    bottom: 10px;
    left: 0px;
  }

  .footer .footer-content {
    /* padding: 0; */
    padding: 10px 10px;
    width: 100%;
  }

  .footer .footer-content .footer-col p {
    font-size: 12px !important;
  }

  .footer-socials svg {
    width: 14px;
    height: 14px;
  }

  .footer .footer-content .footer-col .arrow {
    width: 1.2rem;
    height: 1.2rem;
  }
}
