.contact-copy {
    padding: 0;
    width: 100%;
    height: 100vh;
    background: #fcfbf1;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.contact-copy a {
    text-transform: uppercase;
}

.contact-copy-h1 a h1 {
    color: #000;
    font-size: 16em;
    font-weight: 500;
    letter-spacing: -5px;
    line-height: 100%;
}

.contact-copy-text {
    margin: 3em 0 0 0;
    display: flex;
    flex-direction: column;
}

.contact-copy-text p {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 28px;
    color: #000 !important;
}

.contact-copy-text p a {
    font-weight: 400;
    font-size: 28px;
    color: #000 !important;
}


@media (max-width: 900px) {

    .contact-copy-h1 a h1 {
        font-size: 5em !important;
    }

    .contact-copy-text p {
        font-size: 1.6em !important;
    }
}