.project-container {
  width: 100vw;
  height: 100vh;
  background: var(--project);
  padding: 10em 4em 2em 4em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.project-container * {
  color: var(--bg);
}

.project-img {
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.project-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  mix-blend-mode: normal !important;
}

.project-info {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  gap: 10em;
  align-items: flex-end;
}

.project-info .project-img {
  flex: 5;
  height: 100%;
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
}

.project-info .project-description {
  flex: 2;
}

.project-info .project-description h2 {
  font-size: 3rem;
}

.project-info .project-description p {
  font-size: 1.2rem;
  padding: 10px 0;
  font-weight: 500;
  letter-spacing: -0.3px;
}

.backButton {
  position: fixed;
  margin: 2em;
  width: 24px;
  height: 24px;
  background: var(--bg);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-url {
  color: var(--bg);
  font-weight: 600;
  text-decoration: underline;
}

.project-url:hover {
  color: #7c7c6f;
}

@media (max-width: 900px) {
  .project-container {
    padding-top: 5.5rem;
    gap: 0;
  }

  .project-info {
    flex-direction: column;
    gap: 2rem;
  }

  .project-img {
    flex: 1;
    width: 100%;
  }

  .project-description {
    flex: 1;
  }

  .project-info .project-description h2 {
    font-size: 1.5rem;
  }

  .project-info .project-description p {
    font-size: 0.9rem !important;
    padding-top: 10px;
    font-weight: 500;
    letter-spacing: -0.3px;
  }
}

/* Add these new styles to your existing CSS file */

.tech-stack {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 1rem 0;
}

.tech-badge {
  background-color: var(--bg);
  color: var(--project) !important;
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
  transition: transform 0.2s ease;
}

.tech-badge:hover {
  transform: scale(1.05);
}

.project-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.1);
}

.progress-bar {
  height: 100%;
  background-color: var(--bg);
  transform-origin: left;
}

/* Responsive adjustments */
@media (max-width: 900px) {
  .tech-stack {
    margin: 0.5rem 0;
  }

  .tech-badge {
    font-size: 0.8rem;
    padding: 0.2rem 0.6rem;
  }
}

/* Updated navigation styles */
.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  position: absolute;
  bottom: 8rem;
  left: 50%;
  transform: translateX(-50%);
}

.nav-button {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  border: 2px solid var(--bg);
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  overflow: hidden;
}

.arrow-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.nav-button svg {
  width: 24px;
  height: 24px;
}

@media (max-width: 900px) {
  .nav-button {
    width: 3rem;
    height: 3rem;
  }

  .navigation {
    gap: 1.5em;
    bottom: 6em;
  }

  .nav-button svg {
    width: 20px;
    height: 20px;
  }
}
