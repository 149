header {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 20;
}

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 120rem;
    padding-left: max(1.5rem, min(6.73139vw - .0776699rem, 8rem));
    padding-right: max(1.5rem, min(6.73139vw - .0776699rem, 8rem));
}

.header__inner {
    align-items: center;
    display: flex;
    border-bottom: .0625rem solid #7c7c6f;
    gap: max(2rem, min(5.73529vw - 2.44485rem, 4.4375rem));
    padding-bottom: .6875rem;
    padding-top: .75rem;
}

.header__nav {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    min-height: 3.3125rem;
}

nav {
    display: block;
}

.header__menu {
    display: flex;
    gap: 10rem;
    list-style: none;
}

.header__menu.uppercase {
    text-transform: uppercase;
}

.header__menu-item {
    align-items: center;
    display: flex;
    position: relative;
}

.header__menu-item a {
    align-items: center;
    color: #7c7c6f;
    cursor: pointer;
    display: flex;
    font-size: 1.125rem;
    font-weight: 600;
    height: 100%;
    letter-spacing: -.01em;
    line-height: 1.05;
    padding-bottom: 1rem;
    padding-top: 1rem;
    text-decoration: none;
    transition: color .3s cubic-bezier(.645, .045, .355, 1);
}


.nav-active {
    position: relative;
}

.nav-active::after {
    content: "";
    height: 1px;
    width: 0;
    background: #7c7c6f;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-animation: anim 0.3s ease forwards;
    animation: anim 0.6s ease forwards;
}

.header__menu-item a:hover {
    color: var(--hover-color)
}

@-webkit-keyframes anim {
    to {
        width: 100%;
    }
}

@keyframes anim {
    to {
        width: 100%;
    }
}

.header__menu-toggle {
    display: none;
}

.mobile-nav-container {
    display: none;
}

@media only screen and (max-width: 77.4375rem) {
    .header__nav {
        display: none;
    }

    .header__inner {
        justify-content: center;
        padding-top: 1.75rem;
        border-bottom: none;
    }

    .header__menu-toggle {
        align-items: center;
        background-color: transparent;
        border: 0.125rem solid;
        border-radius: 6.25rem;
        color: var(--text);
        cursor: pointer;
        display: flex;
        font-size: .6875rem;
        font-weight: 600;
        gap: 0.625rem;
        letter-spacing: -.01em;
        line-height: 1;
        padding: 0.727273em 1.27273em;
        position: relative;
        z-index: 1;
    }

    .mobile-nav-container {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        transform: translateY(-100%);
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }

    .mobile-nav-container.show-links {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    .mobile-nav {
        bottom: 0;
        display: flex;
        flex-direction: column;
        left: 0;
        overflow: hidden;
        position: fixed;
        right: 0;
        top: 0;
    }

    .mobile-nav__panel--primary {
        background-color: var(--bg);
        color: var(--text);
        flex: 1;
        overflow: hidden;
    }

    .mobile-nav__menu-wrapper {
        height: 100%;
        overflow: auto;
    }

    .mobile-nav__panel--primary .mobile-nav__menu {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100%;
    }

    .mobile-nav__menu {
        padding: 1.375rem;
    }

    .mobile-nav__list {
        list-style: none;
    }

    .mobile-nav__item {
        margin: 50px 0;
        position: relative;
    }

    .mobile-nav__panel--primary .mobile-nav__item+.mobile-nav__item {
        margin-top: 1rem;
    }

    .mobile-nav__link {
        display: block;
        font-weight: 500;
        letter-spacing: -.03em;
        text-decoration: none;
        width: 100%;
        font-size: 42px;
        text-align: center;
    }

    .nav-active::after {
        display: none;
    }
}