.Home {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 2em;
  overflow-x: hidden;
  overflow-y: scroll;
  background: var(--bg);
  scrollbar-width: none;
}

.hero {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero .headers {
  flex-direction: column;
}

.hero .headers h1 {
  letter-spacing: 0;
}

.hero .headers .header-1 {
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 4cqw;
}

.hero .headers .header-1 h1 {
  font-size: 7.5cqw;
}

.hero .headers .header-2 h1 {
  font-size: 11.75cqw;
  font-family: 'Fira Sans', sans-serif;
  color: #10b03d;
  font-weight: 800;
}

h1 .h1 {
  position: relative;
  /* opacity: 0.2; */
}

.hero .headers .header-2 {
  margin-top: 1.2rem;
  position: relative;
}

@media (max-width: 900px) {
  .hero .headers .header-1 {
    gap: 2cqw;
  }

  .hero .headers .header-1 h1 {
    font-size: 9.6cqw !important;
  }

  .hero .headers .header-2 h1 {
    font-size: 15cqw !important;
    letter-spacing: -1.5px;
  }

  .hero .headers {
    padding-bottom: 6em;
  }
}

section.about {
  margin: 4rem 0;
  width: 100%;
  padding: 10rem 0;
}

.about-container {
  width: 75%;
  margin-left: auto;
  display: flex;
  gap: 10rem;
}

.about-col:nth-child(1) {
  flex: 3;
}

.about-col:nth-child(2) {
  flex: 2;
}

.about-col p:nth-child(1) {
  opacity: 0.5;
}

.socials {
  display: flex;
  flex-direction: column;
}

.projects-nav {
  margin: 8rem 0 2em 0;
  width: 100%;
}

@media (max-width: 900px) {
  .about-container {
    width: 100%;
    flex-direction: column;
    gap: 4em;
  }

  .projects-nav-container {
    width: 100%;
  }
}

.project-link {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 2px solid var(--text);
  height: 110px;
  padding: 1em 0;
}

.project-l {
  display: flex;
  flex: 4;
}

.project-date {
  flex: 2;
}

.project-dir {
  flex: 0.5;
  text-align: right;
}

.project-link .project-link-img {
  margin-right: 1em;
  width: 0;
  height: 100%;
  overflow: hidden;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.project-link:hover .project-link-img {
  width: 120px;
  height: 100%;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.project-link img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

@media (max-width: 900px) {
  .project-l {
    width: 300px;
  }

  .project-date {
    display: none;
  }
}

.animate__svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(2);
}