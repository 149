:root {
  --bg: #0E100F;
  --text: #fcfbf1;
  --project: #fcfbf1;
  --project-text: #0E100F;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: "Neue Montreal", sans-serif;
  overflow: hidden;
}

@font-face {
  font-family: "Fira Sans";
  src: url('./assets/otf/PPHatton-Ultralight.otf');
}

/* animated grains/noise overlay */
.bg {
  position: fixed;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  width: 200%;
  height: 200vh;
  background: transparent url("http://assets.iceable.com/img/noise-transparent.png") repeat 0 0;
  background-repeat: repeat;
  animation: bg-animation 0.2s infinite;
  opacity: 0.15;
  visibility: visible;
  pointer-events: none;
  z-index: 2;
}

@keyframes bg-animation {
  0% {
    transform: translate(0, 0);
  }

  10% {
    transform: translate(-5%, -5%);
  }

  20% {
    transform: translate(-10%, 5%);
  }

  30% {
    transform: translate(5%, -10%);
  }

  40% {
    transform: translate(-5%, 15%);
  }

  50% {
    transform: translate(-10%, 5%);
  }

  60% {
    transform: translate(15%, 0);
  }

  70% {
    transform: translate(0, 10%);
  }

  80% {
    transform: translate(-15%, 0);
  }

  90% {
    transform: translate(10%, 5%);
  }

  100% {
    transform: translate(5%, 0);
  }
}

h1,
h2,
h3 {
  font-weight: 400;
}

h1 {
  font-size: 6.5rem;
  line-height: 0.85;
  letter-spacing: -5px;
}

h2 {
  font-size: 3.375rem;
  line-height: 0.85;
  letter-spacing: -3px;
}

h3 {
  font-size: 0.75rem;
  font-weight: 500;
}

p {
  font-size: 0.75rem;
  font-weight: 500;
}

a {
  text-decoration: none;
  font-weight: 500;
  font-size: 0.75rem;
}

h1,
h2,
h3,
p,
a,
span {
  position: relative;
  color: var(--text);
}

@media (max-width: 900px) {
  h1 {
    font-size: 4rem;
    line-height: 0.85;
    letter-spacing: -2.5px;
  }

  h2 {
    font-size: 2rem;
    letter-spacing: -1.5px;
  }
}